<template>
  <v-container>
    <!-- HEADING -->
    <h1 class="default-bottom-margin">Home</h1>

    <!-- SOCIAL MEDIA LINKS -->
    <v-container>
      <social-media-links
        :links="links"
      />
    </v-container>

    <!-- CURRENT NEWS -->
    <v-container class="default-bottom-margin">
      <v-row
        justify="center"
      >
        <h1>{{ $t('currentNews') }}</h1>
        <v-container>
          <news-entry
            :date="currentNews.date"
            :title="currentNews['title' + $i18n.locale]"
            :text="currentNews['content' + $i18n.locale]"
          />
        </v-container>
      </v-row>
    </v-container>

    <!-- CURRENT VIDEO -->
    <v-container>
      <v-row
        class="default-bottom-margin flex-column"
        align="center"
      >
        <h1>{{ $t('currentVideo') }}</h1>
        <h3>{{ currentVideo.title }}</h3>
        <youtube-video-player
          :videoId="currentVideo.videoId"
          :videoPreviewPicture="currentVideo.previewPicture"
          :width="videoWidth"
          :height="videoHeight"
        />
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import accountLinkData from '@/assets/data/account-link-data.json';
import newsData from '@/assets/data/news-data.json';
import newsEntry from '@/components/news-entry.component.vue';
import socialMediaLinks from '@/components/social-media-links.component.vue';
import videoData from '@/assets/data/videos-data.json';
import youtubeVideoPlayer from '@/components/youtube-video-player.component.vue';

export default {
  name: 'Home',
  metaInfo: {
    meta: [
      { name: 'description', content: 'Webseite des Musikprojekts Synthetic Sympathy.'},
      { name: 'robots', content: 'index, follow'}
    ]
  },
  components: {
    newsEntry,
    socialMediaLinks,
    youtubeVideoPlayer,
  },
  data:() => {
    return {
      currentNews: newsData[0],
      currentVideo: videoData[0],
      links: {
        bandcampLink: accountLinkData[0].bandcamp,
        facebookLink: accountLinkData[0].facebook,
        instagramLink: accountLinkData[0].instagram,
        soundcloudLink: accountLinkData[0].soundcloud,
        spotifyLink: accountLinkData[0].spotify,
        youtubeLink: accountLinkData[0].youtube,
      },
      videoHeight: 300,
      videoWidth: 450,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>